<script setup lang="ts">
import Rellax from "rellax"
import type { PropType } from 'vue';
const mainStore = await useMainStore()
const { siteSettings } = storeToRefs(mainStore)
const props = defineProps({
  headerVisible: {
    type: Boolean,
    default: false,
  },
  texts: {
    type: Object,
    default: () => { },
  },
  align: {
    type: String as PropType<"left" | "right" | "center">,
    default: "center",
  },
  verticalAlign: {
    type: String as PropType<"top" | "center" | "bottom">,
    default: "bottom",
  },
})
onMounted(() => {
  new Rellax(".rellax-header", {
    center: false,
    round: true,
    vertical: true,
    horizontal: true,
  })
})

const leadingHeaderTitle = computed(() => {
  return props.texts && props.texts?.leadingHeaderTitle
    ? props.texts.leadingHeaderTitle
    : siteSettings?.value
    ? siteSettings?.value.leadingHeaderTitle
    : ""
})
const headerTitle = computed(() => {
  return props.texts && props.texts?.headerTitle
    ? props.texts.headerTitle
    : siteSettings?.value
    ? siteSettings?.value.headerTitle
    : ""
})
const subHeaderTitle = computed(() => {
  return props.texts && props.texts.subHeaderTitle
    ? props.texts.subHeaderTitle
    : siteSettings?.value
    ? siteSettings?.value.subHeaderTitle
    : ""
})
</script>
<template>
  <div class="relative flex w-full h-full items-center transition-all duration-500 ease-in-out">
    <slot />
    <div class="header-tagline absolute inset-0 z-1 w-full h-full flex-1 flex @lg/photo:items-center" :class="{'items-center': props.verticalAlign === 'center', 'items-start': props.verticalAlign === 'top', 'items-end': props.verticalAlign === 'bottom'}">
      
        <!-- rellax -->
        <div class="py-12 px-6 md:px-10 w-full flex" :class="{'justify-end text-right': props.align === 'right', 'justify-start text-left': props.align === 'left', 'justify-center text-center': props.align === 'center'}">
          <div class="max-w-8xl">
            <div class="text-white font-medium text-6xl md:text-8xl lg:text-9xl tracking-wider leading-none font-script rellax-header"
              data-rellax-speed="2">
              {{ leadingHeaderTitle }}
            </div>
            <h1
              class="text-white text-4xl md:text-6xl lg:text-7xl 2xl:text-9xl tracking-tighter leading-none font-serif uppercase pt-8 pb-4 text-shadow rellax-header break-all_1 md:break-normal"
              :class="{ 'opacity-100': headerVisible }" data-rellax-speed="1">
              {{ headerTitle }}
            </h1>
            <div class="text-white text-xl md:text-4xl tracking-tight font-sans rellax-header"
              data-rellax-speed="-1">
              {{ subHeaderTitle }}
            </div>
          </div>
        </div>
      </div>
    
  </div>
</template>
<style>
.header-tagline span {
  text-shadow: 0 0 20px #000000d9;
}

.header-photo-wrapper {
  position: relative;
  height: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.header-photo-wrapper.has-photo {
  height: calc(100vh - 80px);

  @media all and (min-width: 768px) {
    height: calc(100vh - 244px);
  }
}

.header-photo-wrapper .parallax {
  background-attachment: fixed;
  clear: both;
}
</style>
